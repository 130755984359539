import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DefaultProperty } from '../../data/Constants';
import { ApiKeyApi } from '../../api/ApiKeyApi';
import { IApiKeyState } from '../../../types/IComponentTypes';

//To get paginated ApiKeys data
export const getApiKeys = createAsyncThunk( 'administration/getApiKeys', async ( action:{ token: string, page:number, limit:number, searchCode?: string} )=>{
  return ApiKeyApi.getApiKeys( action );
} )

//To create api key
export const createApiKey = createAsyncThunk( 'apikeys/createApiKey', async ( action:{ token: string, request } )=>{
  return ApiKeyApi.createApiKey( action );
} );

//To update api key
export const updateApiKey = createAsyncThunk( 'apikeys/updateApiKey', async ( action:{ token: string, request } )=>{
  return ApiKeyApi.updateApiKey( action );
} );

//To delete apikey
export const deleteApiKeys = createAsyncThunk( 'apikeys/deleteApiKeys', async ( action:{ token: string, request } )=>{
  return ApiKeyApi.deleteApiKeys( action );
} );

//Gets the apikey details
export const getApiKeyDetails = createAsyncThunk( 'apikeys/getApiKeyDetails', async ( action:{ token: string, id: string } )=>{
  return ApiKeyApi.getApiKeyDetails( action );
} );

//To get the service users for apikey management tab
export const getServiceUsers = createAsyncThunk( 'apikeys/getServiceUsers', async ( action:{ token: string } )=>{
  return ApiKeyApi.getServiceUsers( action );
} );

//To get the configit property list for apikey management tab
export const getConfigitPropertyList = createAsyncThunk( 'apikeys/getConfigitPropertyList', async ( action:{ token: string } )=>{
  return ApiKeyApi.getConfigitPropertyList( action );
} );

//To get the pdm property list for apikey management tab
export const getPdmPropertyList = createAsyncThunk( 'apikeys/getPdmPropertyList', async ( action:{ token: string } )=>{
  return ApiKeyApi.getPdmPropertyList( action );
} );

//To get the privileges
export const getPrivileges = createAsyncThunk( 'apikeys/getPrivileges', async ( action:{ token: string } )=>{
  return ApiKeyApi.getPrivileges( action );
} );


const initialState:IApiKeyState = {
  serviceUsers: [],
  properties:{
    configit: [],
    pdm: []
  },
  privileges:[]    
}

const apikeySlice = createSlice( {
  name: 'apikeys',
  initialState,
  reducers: {},
  extraReducers: ( builder ) =>{
    builder.addCase( getServiceUsers.fulfilled, ( state, action ) => {
      if( !action.payload.error ) {
        state.serviceUsers = action.payload.data;
      }
    } );
    builder.addCase( getConfigitPropertyList.fulfilled, ( state, action ) => {
      if( !action.payload.error ) {
        state.properties.configit = [ ...DefaultProperty, ...action.payload.data ];
        state.properties.configit = state.properties.configit.filter( prop=>prop.code !== '' )
      }
    } );
    builder.addCase( getPdmPropertyList.fulfilled, ( state, action ) => {
      if( !action.payload.error ) {
        state.properties.pdm = [ ...DefaultProperty, ...action.payload.data ];
        state.properties.pdm = state.properties.pdm.filter( prop=>prop.code !== '' && prop.isActive )
      }
    } );
    builder.addCase( getPrivileges.fulfilled, ( state, action ) => {
      if( !action.payload.error ) {
        state.privileges = action.payload.data;
      }
    } );
  }
} )

export default apikeySlice.reducer