import { handleError } from '../services/DataHelperFunctions';
import { EAction, EApiType, EUrlParams } from '../data/Constants';
import AxiosClient from './AxiosClient';
import { getHeaders } from './HttpUtil';

function handleSuccess( response ) {
  return {
    data: response.data
  } 
}

export const FeatureFlagApi = {
  getFeatureFlags: ( action: { token: string, page:number, limit:number, searchCode?: string, activeOnly?: boolean } ) => {
    let queryParams = `${EUrlParams.Page}=` + action.page + `&${EUrlParams.Limit}=` + action.limit;
    queryParams = ( action.searchCode ? `${EUrlParams.Search}=` + action.searchCode + '&' : '' ) + queryParams;
    return AxiosClient
      .post( `/featureflag/v1/read?${queryParams}`,
        {
          featureFlags: [],
          activeOnly: action.activeOnly
        },
        getHeaders( action.token )
      )
      .then( ( response ) => {
        const total = JSON.parse( response.headers['x-pagination'] ).Total;
        return {
          data: response.data,
          searchKey: action.searchCode,
          page: action.page,
          totalRecords: total,
          recordsPerPage: action.limit
        }
      } ).catch( ( err ) => {
        if( err.response.status === 404 || err.response.status === 400 ) { //To set the state data as empty array so that UI doesn't break and display proper error message
          return {
            data: [],
            searchKey: action.searchCode,
            page: action.page,
            totalRecords: 1
          }
        }
        return handleError( err, EAction.Read, EApiType.FeatureFlagsKey );
      } )
  },

  createFeatureFlag: ( action: { token: string, request } ) => {
    return AxiosClient
      .post( '/featureflag/v1/create',
        action.request, getHeaders( action.token )
      )
      .then( ( response ) => {
        return handleSuccess( response ) 
      } ).catch( ( err ) => {
        return handleError( err,EAction.Create );
      } )
  },

  updateFeatureFlag: ( action: { token: string, request } ) => {
    return AxiosClient
      .post( '/featureflag/v1/update',
        action.request, getHeaders( action.token )
      )
      .then( ( response ) => {
        return handleSuccess( response ) 
      } ).catch( ( err ) => {
        return handleError( err,EAction.Update );
      } )
  },

  deleteFeatureFlags: ( action: { token: string, request } ) => {
    return AxiosClient
      .post( '/featureflag/v1/delete', action.request, getHeaders( action.token )
      )
      .then( ( response ) => {
        return handleSuccess( response ) 
      } ).catch( ( err ) => {
        return handleError( err );
      } )
  },

  getFeatureFlagDetails: ( action: { token: string, request } ) => {
    return AxiosClient
      .post( '/featureflag/v1/read',
        {
          featureFlags: action.request,
          activeOnly: false
        },
        getHeaders( action.token )
      )
      .then( ( response ) => {
        return handleSuccess( response ) 
      } ).catch( ( err ) => {
        return handleError( err, EAction.Get );
      } )
  }

}