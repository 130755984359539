import Dropzone from 'dropzone';
import { appSettings } from '../settings';
import 'dropzone/dist/dropzone.css';
import { EDataMaintenanceType, EFileFormat, EFileSizeBytes, EFileSizeMB, EImportStatus } from '../data/Constants';
import { IChunkUpload, IImportAPIConfig } from '../../types';
import { removeUploadFile, setChunkFileUpload, setUploadFile } from '../store/states/DataMaintenanceSlice';
import { AppDispatch, AppStore } from '../store';
import { setBusy, setNotBusy } from '../store/states/BusySlice';
import { Trans } from 'react-i18next';
import { setTechnicalInfoMsg } from '../store/states/TechnicalInfoSlice';
import React from 'react';


const getImportApiDetails = ( type:string )=>{ //method which returns the file import api details based on type
  switch ( type ) {
    case EDataMaintenanceType.Prices : return { paramName:ImportConfig.Price.filename, url:ImportConfig.Price.url, fileType:EFileFormat.Zip, chunkSize:EFileSizeBytes.KB2000, maxFileSize:EFileSizeMB.MB20,maxZipFileName:50,maxFileName:80 };
    case EDataMaintenanceType.SalesText : return { paramName:ImportConfig.SalesText.filename, url:ImportConfig.SalesText.url, fileType:EFileFormat.Zip,chunkSize:EFileSizeBytes.KB1500, maxFileSize:EFileSizeMB.MB10, maxZipFileName:50 };
    case EDataMaintenanceType.ProductHierarchy : return { paramName:ImportConfig.ProductHierarchy.filename, url:ImportConfig.ProductHierarchy.url, fileType:EFileFormat.Xlsx, chunkSize:EFileSizeBytes.KB50, maxFileSize:EFileSizeMB.MB5, maxZipFileName:50 };
    case EDataMaintenanceType.TechnicalInfo : return { paramName:ImportConfig.TechnicalInfo.filename, url:ImportConfig.TechnicalInfo.url, fileType:EFileFormat.Xlsx, chunkSize:EFileSizeBytes.KB50, maxFileSize:EFileSizeMB.MB5, maxZipFileName:50 };
    default: return null;
  }
}

const resetDropzone = ( chunkUpload: IChunkUpload, dispatch: AppDispatch )=>{ //reset the chunkUpload state variables after complete the Upload or while any error occured.
  const updatePropsData = JSON.parse( JSON.stringify( chunkUpload ) );
  updatePropsData.fileName = '';
  updatePropsData.fileId = '';
  updatePropsData.isUploaded = false;
  updatePropsData.isUploadProgress = false
  
  dispatch( setChunkFileUpload( updatePropsData ) );
}

const getFileInfo = ( chunkUpload: IChunkUpload ,fileId:string,fileName:string, dispatch: AppDispatch )=>{ //set the chunkUpload property while click import file in UI
  const updatePropsData = JSON.parse( JSON.stringify( chunkUpload ) );
  updatePropsData.fileName = fileName;
  updatePropsData.fileId = fileId;
  updatePropsData.isUploadProgress = fileId;
  dispatch( setChunkFileUpload( updatePropsData ) );
  return {...updatePropsData};
}

//returns the  import api headers
const getApiHeaders = ( token: string )=>{
  return {
    Authorization: `Bearer ${token}`,
    Context: appSettings.Context
  }
}

const getDropzoneOptions = ( token: string ,apiConfig:IImportAPIConfig )=>{ //returns the default dropzone options based on the different import type
  return{
    maxFilesize:apiConfig?.maxFileSize,
    clickable:true,
    addRemoveLinks:false,
    maxFiles:1,
    autoProcessQueue:false,
    createImageThumbnails:false,
    paramName:apiConfig?.paramName,
    acceptedFiles:apiConfig?.fileType,
    uploadMultiple: false,
    chunking: true,
    forceChunking: true,
    chunkSize:apiConfig?.chunkSize, //in bytes
    parallelChunkUploads: false,
    retryChunks: true,
    retryChunksLimit: 2,
    url:`${appSettings.DisEndpoint}${apiConfig?.url}`,
    headers:getApiHeaders( token ),
    autoDiscover:true,
    disablePreviews: true
  }
}

const handleFailedImport = ( type: string, fileName: string, logFileName: string, dispatch: AppDispatch ) => {
  if( type == EDataMaintenanceType.TechnicalInfo ) {
    const msg = <Trans i18nKey="messages.fail.technicalInfo" components={ { 1: <span style={ {color:'red'} }/>, file: fileName, timestamp: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString(), logFileName: logFileName } } />; 
    dispatch( setTechnicalInfoMsg( {message: msg} ) )
  }
}

//Shows appropriate error messages for errors
const showErrorAlerts = ( file: Dropzone.DropzoneFile, apiConfig: IImportAPIConfig, type: string, dispatch: AppDispatch, showAlertMessage, logFileName: string ) =>{
  if( !file.accepted && !file.name.includes( apiConfig.fileType ) ) {
    showAlertMessage( EImportStatus.InvalidType, apiConfig.fileType, apiConfig.maxFileSize ); //show the error message if file format is invalid
  } else if( !file.accepted && file.size > apiConfig?.maxFileSize * 1024 * 1024 ) {
    showAlertMessage( EImportStatus.InvalidSize, apiConfig.fileType, apiConfig.maxFileSize ); //show the error message if file size is invalid
  } else if ( file.xhr?.response.includes( 'InvalidFileName:\'' ) ) {
    const fileName = file.xhr?.response.split( 'InvalidFileName:\'' )[1]?.split( '\'' )[0];
    showAlertMessage( EImportStatus.InvalidFileName, apiConfig.fileType, apiConfig.maxFileSize, fileName ); //show the error message if the file name length is greater than apiConfig.maxFileSize
  } else if( file.status === EImportStatus.Error ) {
    showAlertMessage( EImportStatus.Error, apiConfig.fileType, apiConfig.maxFileSize ); //show the error message if API is getting failed.
    handleFailedImport( type, file.name, logFileName, dispatch );
  }
}

export const initDropZone = ( token: string, type: string, showAlertMessage )=>{ 
  const dispatch = AppStore.dispatch;
  const apiConfig:IImportAPIConfig = getImportApiDetails( type );
  
  const chunkUpload = AppStore.getState().dataMaintainance.chunkUpload;

  return new Dropzone( '#dropzone-upload',//initialize the dropzone options and events
    {
      ...getDropzoneOptions( token,apiConfig ),
      
      chunksUploaded( file ) { //this event will trigger aftere all chunks files are uploaded the server
        dispatch( setNotBusy() );
        dispatch( removeUploadFile() );
        resetDropzone( chunkUpload, dispatch );
        showAlertMessage( EImportStatus.Success )
        if( type === EDataMaintenanceType.TechnicalInfo ) {
          const msg = <Trans i18nKey="messages.success.upload.technicalInfo" components={ { 1: <span style={ {color:'green'} }/>, file: file.name, timestamp: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString() } } />; 
          dispatch( setTechnicalInfoMsg( {message: msg } ) )
        }
      },
      init: function () {
        this.on('success', async (file, response) => {
          console.log(response);
        })
        this.on( 'addedfile', ( file ) =>{ //this event trigger after user selected the file from the folder.
          file.name.length <= 50 ? dispatch( setUploadFile( {file: file, importDropzone: this} ) ) //set upload file info to the state
            : showAlertMessage( EImportStatus.InvalidZipFileName, apiConfig.fileType, 0, file.name )
          
        } );

        this.on( 'sending', ( file, xhr ) =>{ //this event is trigger while sending file to API
          const isLastChunk = file?.upload?.totalChunkCount === file?.upload?.chunks.length ;
          file?.upload?.chunks.length === 1 ? initializeUpload.call(this, file, xhr) : setChunkHeaders(xhr, this.chunkUpload);  

          xhr.setRequestHeader( ApiHeaders.CurrentChunk, file?.upload?.chunks.length ); //set the currentChunk and totalChunk to the request header
          xhr.setRequestHeader( ApiHeaders.TotalChunk, file?.upload?.totalChunkCount );

          if( isLastChunk ) { //set isUploaded flag to true once all the chunk files are uploaded to the API to changing the loader message
            this.chunkUpload.isUploaded = true;
            this.chunkUpload.isUploadProgress = false;
          }
        
          this.chunkUpload.uploadValue = file?.upload?.progress;
          dispatch( setChunkFileUpload( {...this.chunkUpload} ) );


        } );

        this.on( 'error', ( file, message, xhr )=> { //this event will trigger while getting error from the API or invalid file format/invalid file size                  
          dispatch( setNotBusy() );//reset the loader
          dispatch( removeUploadFile() );
          let logFileName = '';
          if( this.element.dropzone ) {
            this.element.dropzone.destroy();
          }

          logFileName = handleLogFileDownload(xhr);

          showErrorAlerts( file, apiConfig, type, dispatch, showAlertMessage, logFileName )
        } );
      },
    } );

    function initializeUpload(this: Dropzone, file: File, xhr: XMLHttpRequest) {
      dispatch(setBusy());
  
      const fileId = file.upload.uuid.replaceAll('-', '');
      const uploadFileName = fileId + '_' + file.name.replace(/\s/g, '');
  
      xhr.setRequestHeader(ApiHeaders.FileName, uploadFileName);
      xhr.setRequestHeader(ApiHeaders.FileId, fileId);
  
      this.chunkUpload = getFileInfo(chunkUpload, fileId, uploadFileName, dispatch);
    }

    function setChunkHeaders(xhr: XMLHttpRequest, chunkUploadData: any) {
      xhr.setRequestHeader(ApiHeaders.FileName, chunkUploadData.fileName);
      xhr.setRequestHeader(ApiHeaders.FileId, chunkUploadData.fileId);
    }

    function handleLogFileDownload(xhr: XMLHttpRequest): string {
      let logFileName = '';
      //check if xhr.response has file content
      //Auto download the log file
      try {
          const response = JSON.parse(xhr.response);
          if (response.fileContents) {
              logFileName = response.fileDownloadName;
              const byteCharacters = atob(response.fileContents);
              const byteNumbers = new Array(byteCharacters.length);
              for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);
              const blob = new Blob([byteArray], { type: response.contentType });
  
              const link = document.createElement('a');
              link.href = URL.createObjectURL(blob);
              link.download = response.fileDownloadName || 'technicalInfoLogFile';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
          }
      } catch (e) {
          console.error('Error parsing response:', e);
      }
      return logFileName;
    }
};

export const ImportConfig = {
  SalesText: {filename: 'salesTextXmlZip', url : '/salestext/v1/create/file'},
  ProductHierarchy: {filename: 'file', url : '/product/v1/hierarchy/create/file'},
  Price: {filename: 'pricingFile', url : '/price/v1/create/file'},
  TechnicalInfo: {filename: 'technicalInfoFile', url : '/technicalinfo/v1/create/file'}
} 

export const ApiHeaders = {
  FileName: 'fileName',
  FileId: 'fileId',
  CurrentChunk: 'currentChunk',
  TotalChunk: 'totalChunk'
}
